import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  ref: "titleSection",
  class: "title-section"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "tool-section"
}
const _hoisted_5 = { class: "container" }
const _hoisted_6 = {
  key: 0,
  class: "breadcrumb"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigator = _resolveComponent("navigator")!
  const _component_breadcrumb = _resolveComponent("breadcrumb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.displayNavigator)
      ? (_openBlock(), _createBlock(_component_navigator, { key: 0 }))
      : _createCommentVNode("", true),
    ($props.title != null)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString($props.title), 1),
            ($props.subtitle)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.subtitle), 1))
              : _createCommentVNode("", true)
          ])
        ], 512))
      : _createCommentVNode("", true),
    ($props.displayBreadcrumb)
      ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            ($props.displayBreadcrumb)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_breadcrumb)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}